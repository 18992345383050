import React, { useState } from 'react';
import { storyblokEditable } from "@storyblok/react";
import emailjs from '@emailjs/browser';

function ContactForm({ blok }) {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });

    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;

        if (name === 'phone') {
            newValue = value.replace(/[^\d\s+()-]/g, '');
        }

        setFormData({ ...formData, [name]: newValue });
        setErrors({ ...errors, [name]: "" });
    };

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        if (!formData.name.trim()) {
            errors.name = 'Name is required';
            formIsValid = false;
        }

        if (!formData.phone.trim()) {
            errors.phone = 'Phone is required';
            formIsValid = false;
        } else if (!/^\+?\d{1,3}?[- .]?\(?(?:\d{1,3})\)?[- .]?\d{1,5}[- .]?\d{1,5}[- .]?\d{1,5}$/.test(formData.phone)) {
            errors.phone = 'Invalid phone number';
            formIsValid = false;
        }

        if (!formData.email.trim()) {
            errors.email = 'Email is required';
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email address is invalid';
            formIsValid = false;
        }

        if (!formData.message.trim()) {
            errors.message = 'Message is required';
            formIsValid = false;
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setSubmitting(true);
            emailjs.sendForm(
                'service_sez340g',
                'template_mldju7k',
                e.target,
                'wFK9F-KQXmgY_LjA0'
            ).then(
                result => {
                    console.log('Email sent:', result.text);
                    setFormData({name: '', phone: '', email: '', message: ''});
                    setSubmitting(false);
                },
                error => {
                    console.log('Email sending error:', error.text);
                    setSubmitting(false);
                    alert('Failed to send email: ' + error.text);
                }
            );
        }
    };

    return (
        <form onSubmit={handleSubmit} {...storyblokEditable(blok)}>
            <div className="contact-input">
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder={blok.name}
                />
                {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
            </div>
            <div className="contact-input">
                <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder={blok.phone}
                />
                {errors.phone && <p style={{ color: 'red' }}>{errors.phone}</p>}
            </div>
            <div className="contact-input">
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder={blok.email}
                />
                {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
            </div>
            <div className="contact-text">
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder={blok.message}
                />
                {errors.message && <p style={{ color: 'red' }}>{errors.message}</p>}
            </div>
            <button type="submit" className="contact-submit" disabled={submitting}>
                {submitting ? 'Sending...' : blok.send_button}
                <i className="link-icon"/>
            </button>
        </form>
    );
}

export default ContactForm;
