import React from "react";
import { storyblokEditable } from "@storyblok/react";
import Teaser from "./Teaser";

const Artwheels = ({ blok }) => {
    return (
        <section className="artwheels" {...storyblokEditable(blok)}>
            <div className="artwheels-text-wrapper">
                {blok.h2_subtitle && blok.h2_subtitle.map(title => (
                    <Teaser key={title._uid} blok={title}/>
                ))}
                <div className="artwheels-text-icon-container">
                    <div className="artwheels-icon">
                        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M32.9018 7.34953C19.378 4.51344 6.94336 15.01 6.94336 28.5044C6.94336 40.4184 16.6137 50.1111 28.5004 50.1111C40.3871 50.1111 50.0576 40.4184 50.0576 28.5044C50.0576 18.31 42.8419 9.4131 32.9018 7.34953ZM45.216 17.7006L31.7202 23.919L33.4461 9.16851C38.4012 10.4373 42.5521 13.5658 45.216 17.7006ZM48.166 25.437L33.962 28.2846C33.9167 27.1316 33.516 26.0714 32.8644 25.2076L46.0554 19.1296C47.0932 21.0751 47.8167 23.2023 48.166 25.437ZM28.5038 32.3386C26.3935 32.3386 24.6767 30.6172 24.6767 28.5012C24.6767 26.3888 26.3935 24.6703 28.5038 24.6703C30.6104 24.6703 32.3239 26.3888 32.3239 28.5012C32.3241 30.6172 30.6104 32.3386 28.5038 32.3386ZM8.80599 25.6244C9.13675 23.3678 9.84858 21.2176 10.8809 19.2505L24.0535 25.3201C23.4152 26.2116 23.0344 27.2991 23.029 28.4759L8.80599 25.6244ZM31.8258 8.82896L30.1359 23.2709C29.0772 22.9385 27.9321 22.937 26.865 23.2726L25.175 8.82908C27.3219 8.4657 29.5285 8.44021 31.8258 8.82896ZM23.5546 9.16885L25.298 24.0681C25.2952 24.0701 25.2926 24.0722 25.2899 24.0742L11.7099 17.8168C14.3704 13.6249 18.5532 10.4505 23.5546 9.16885ZM8.6351 27.2718L23.3044 30.2128C23.3212 30.2639 23.3414 30.3133 23.3597 30.3637L13.2652 41.3322C10.0052 37.4474 8.31247 32.4702 8.6351 27.2718ZM14.379 42.5567L24.2012 31.8839C24.893 32.7652 25.8455 33.4299 26.944 33.7577L19.8264 46.465C17.7941 45.4746 15.9544 44.1476 14.379 42.5567ZM21.347 47.1243L28.711 33.977C28.739 33.9759 28.7668 33.9742 28.7947 33.9728L36.0692 46.9598C31.4876 48.8551 26.215 49.0104 21.347 47.1243ZM37.5685 46.2624L30.4816 33.6105C31.5327 33.1995 32.4286 32.4758 33.0486 31.5484L42.9052 42.2587C41.3691 43.8748 39.5671 45.2326 37.5685 46.2624ZM43.9984 41.0117L33.8534 29.988L48.3533 27.0811C48.7281 32.3048 47.0601 37.2066 43.9984 41.0117Z"
                                fill="#CBCBCB"/>
                            <path
                                d="M34.0446 1.86367C34.044 1.86344 34.0438 1.86344 34.0432 1.86344C17.0104 -1.6631 1.35352 11.49 1.35352 28.504C1.35352 43.5063 13.5316 55.7115 28.5007 55.7115C43.4697 55.7115 55.6478 43.5063 55.6478 28.504C55.6478 15.6754 46.5622 4.47153 34.0446 1.86367ZM28.5007 54.0626C14.4409 54.0626 3.00251 42.5971 3.00251 28.504C3.00251 12.5827 17.6426 0.154 33.7081 3.4777C45.4654 5.92725 53.9989 16.4523 53.9989 28.504C53.9988 42.5971 42.5604 54.0626 28.5007 54.0626Z"
                                fill="#CBCBCB"/>
                        </svg>
                    </div>
                    <p className="artwheels-paragraph">{blok.artwheelstext}</p>
                </div>
            </div>
            <div className="artwheels-image">
                <img src={blok.artwheelsimage.filename} alt={blok.artwheelsimage.alt}/>
            </div>
        </section>
    );
};

export default Artwheels;
