import React, {useState} from "react";
import ReactDOM from 'react-dom';
import { storyblokEditable } from "@storyblok/react";
import Teaser from "./Teaser";
import ContactForm from "./ContactForm";
import OrderForm from "./OrderForm";

const Consult = ({ blok }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
        document.body.classList.add('body-no-scroll');
    };

    const closeModal = () => {
        setModalOpen(false);
        document.body.classList.remove('body-no-scroll');
    };


    const modalContent = (
        <div className="modal">
            <div className="order-title-wrapper">
                <h2>{blok.order[0].title}</h2>
                <button onClick={closeModal}>
                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.3402 33.6587C10.6152 33.9337 10.9635 34.062 11.3118 34.062C11.6602 34.062 12.0268 33.9337 12.2835 33.6587L22.0002 23.942L31.7168 33.6587C31.9918 33.9337 32.3402 34.062 32.6885 34.062C33.0368 34.062 33.3852 33.9337 33.6602 33.6587C34.1918 33.127 34.1918 32.247 33.6602 31.7154L23.9435 21.9987L33.6602 12.282C34.1918 11.7504 34.1918 10.8704 33.6602 10.3387C33.1285 9.80702 32.2485 9.80702 31.7168 10.3387L22.0002 20.0554L12.2835 10.3387C11.7518 9.80702 10.8718 9.80702 10.3402 10.3387C9.80849 10.8704 9.80849 11.7504 10.3402 12.282L20.0568 21.9987L10.3402 31.7154C9.80849 32.247 9.80849 33.127 10.3402 33.6587Z"
                            fill="#373737"/>
                    </svg>
                </button>
            </div>
            <OrderForm blok={blok.order[0]} closeModal={closeModal} />
        </div>
    );

    return (
        <section className="consult" {...storyblokEditable(blok)}>
            <div className="consult-wrapper">
                <div className="consult-container">
                    <div className="consult-text">
                        {blok.h2_subtitle && blok.h2_subtitle.map(title => (
                            <Teaser key={title._uid} blok={title}/>
                        ))}
                    </div>
                    <div className="contact-form">
                        {blok.contact && blok.contact.map((contactItem) => (
                            <ContactForm key={contactItem._uid} blok={contactItem}/>
                        ))}
                    </div>
                </div>
                <div className="consult-info-wrapper">
                    <h2>{blok.consult_text}</h2>
                    <div className="btn-wrapper order-btn-modal" onClick={openModal}>
                        {blok.order_btn}
                        <i className="link-icon"/>
                    </div>
                </div>
            </div>
            {isModalOpen ? ReactDOM.createPortal(modalContent, document.body) : null}
        </section>
    );
};

export default Consult;