import React from "react";
import { createRoot } from "react-dom/client";
import 'normalize.css';
import "./index.css";
import App from "./App";
import { LanguageProvider } from './contexts/LanguageContext';

import { storyblokInit, apiPlugin } from "@storyblok/react";

import Page from "./components/Page";
import Teaser from "./components/Teaser";
import Grid from "./components/Grid";
import Feature from "./components/Feature";
import Title from "./components/Title";
import Header from "./components/Header";
import HeaderWheels from "./components/HeaderWheels";
import ButtonLink from "./components/ButtonLink";
import Gallery3D from "./components/3d-Gallery";
import Image3d from "./components/Image3d";
import Advantages from "./components/Advantages";
import Artwheels from "./components/Artwheels";
import TracktorsGallery from "./components/Tracktors-gallery";
import AudioSpeakers from "./components/Audio";
import Consult from "./components/Consult";
import Production from "./components/Production";
import Stage from "./components/Stage-production";
import Footer from "./components/Footer";
import Materials from "./components/Materials";
import MaterialsItem from "./components/Materials-item";
import Life from "./components/Life";
import LifeItem from "./components/Life-item";
import Services from "./components/Services";
import ServiceItem from "./components/Service-item";
import ProductsList from "./components/ProductsList";
import WheelsLink from "./components/Wheels_link";
import ProductsListItem from "./components/ProductsListItem";
import Map from "./components/Map";
import PartnerItem from "./components/PartnerItem";
import WheelsItem from "./components/Wheels-item";
import Wheels from "./components/Wheels";
import FilterWheels from "./components/Filter-wheels";
import ContactForm from "./components/ContactForm";
import ContactsPage from "./components/ContactsPage";
import TextSeo from "./components/TextSeo";
import OrderForm from "./components/OrderForm";
import ArtModel from "./components/3d-model-Artwheel";
import DualModel from "./components/3d-model-DualStandart";
import NarrowModel from "./components/3d-model-Narrow";


storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_TOKEN,
  use: [apiPlugin],
  components: {
    page: Page,
    h2_subtitle: Teaser,
    grid: Grid,
    feature: Feature,
    h1_title: Title,
    header: Header,
    header_wheels: HeaderWheels,
    iconbuttonlink: ButtonLink,
    wheels3d: Gallery3D,
    image3d: Image3d,
    advantages: Advantages,
    artwheels: Artwheels,
    tracktors_gallery: TracktorsGallery,
    audio: AudioSpeakers,
    consultation: Consult,
    production: Production,
    production_stage: Stage,
    materials: Materials,
    materials_item: MaterialsItem,
    volya_life: Life,
    volya_life_item: LifeItem,
    services: Services,
    service_item: ServiceItem,
    wheels_link_block: WheelsLink,
    products_about_us: ProductsList,
    products_list_item: ProductsListItem,
    map: Map,
    partners_item: PartnerItem,
    wheels: Wheels,
    wheel_type_item: WheelsItem,
    filter: FilterWheels,
    contact_form: ContactForm,
    contacts_page_text: ContactsPage,
    text_seo: TextSeo,
    order_form: OrderForm,
    model_wheel_art: ArtModel,
    model_wheel_dual: DualModel,
    model_wheel_narrow: NarrowModel,
    footer: Footer
  },
  apiOptions: {
    // for spaces located in the US or China:
    // region: "us" or "cn", // you need to specify the region
    region: ''
  }
});

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <React.StrictMode>
      <LanguageProvider>
        <App />
      </LanguageProvider>
  </React.StrictMode>
);
