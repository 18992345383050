import React from 'react';
import { storyblokEditable } from "@storyblok/react";
import { useLanguage } from "../contexts/LanguageContext";
import { cleanUrl } from "../utils/cleanUrl";


const Footer = ({ blok }) => {
    const { language, setLanguage } = useLanguage();

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    return (
        <footer className="footer-wrapper">
            <div className="footer-nav-wrapper">
                <div className="footer-logo">
                    {blok && blok.logo && (
                        <a href={cleanUrl(blok.nav_link_home.cached_url)}><img src={blok.logo.filename} alt="Logo"/></a>
                    )}
                </div>

                <div className="footer-address">
                    {blok && blok.company_address_1 && (
                        <div className="footer-adress-email-head">{blok.company_address_1}</div>
                    )}
                    {blok && blok.company_address_2 && (
                        <div className="address-text">{blok.company_address_2}</div>
                    )}
                </div>
                <div className="footer-contacts">
                    <div className="footer-adress-email-head">Email</div>
                    {blok && blok.email && (
                        <div className="email">{blok.email}</div>
                    )}
                </div>
                <nav {...storyblokEditable(blok)} className="footer-nav">
                    <div className="footer-nav-links-container">
                        <div className="footer-nav-links">
                            {blok && blok.nav_link_home && (
                                <a href={cleanUrl(blok.nav_link_home.cached_url)}>{blok.home_link_name}</a>
                            )}
                        </div>
                        <div className="footer-nav-links">
                            {blok && blok.nav_link_about_us && (
                                <a href={cleanUrl(blok.nav_link_about_us.cached_url)}>{blok.about_link_name}</a>
                            )}
                        </div>
                    </div>
                    <div className="footer-nav-links-container">
                        <div className="footer-nav-links">
                            {blok && blok.nav_link_production && (
                                <a href={cleanUrl(blok.nav_link_production.cached_url)}>{blok.production_link_name}</a>
                            )}
                        </div>
                        <div className="footer-nav-links">
                            {blok && blok.nav_link_wheels && (
                                <a href={cleanUrl(blok.nav_link_wheels.cached_url)}>{blok.wheels_link_name}</a>
                            )}
                        </div>
                    </div>
                    <div className="footer-nav-links-container">
                        <div className="footer-nav-links">
                            {blok && blok.nav_link_contacts && (
                                <a href={cleanUrl(blok.nav_link_contacts.cached_url)}>{blok.contacts_link_name}</a>
                            )}
                        </div>
                        <div className="footer-nav-links">
                            <select className="lang-switcher" value={language} onChange={handleLanguageChange}>
                                <option value="de">DE</option>
                                <option value="en">EN</option>
                                <option value="es">ES</option>
                                <option value="fr">FR</option>
                                <option value="it">IT</option>
                                <option value="pl">PL</option>
                            </select>
                        </div>
                    </div>
                </nav>
            </div>

            <div className="footer-copyright">
                <div>2024, @ volyadesign.com</div>
                <a href={cleanUrl(blok.nav_link_home.cached_url)}>Privacy Policy</a>
            </div>
        </footer>
    );
};

export default Footer;
