import React, { useState } from "react";
import { storyblokEditable } from "@storyblok/react";

const FilterWheels = ({ blok, onChange }) => {
    const [selectedTypes, setSelectedTypes] = useState(new Set());
    const [selectedBrands, setSelectedBrands] = useState(new Set());
    const [selectedCombainers, setSelectedCombainers] = useState(new Set());
    const [selectedSprayers, setSelectedSprayers] = useState(new Set());

    const handleTypeChange = (type) => {
        const newSelectedTypes = new Set(selectedTypes);
        if (newSelectedTypes.has(type)) {
            newSelectedTypes.delete(type);
        } else {
            newSelectedTypes.add(type);
        }
        setSelectedTypes(newSelectedTypes);
        onChange('wheelsType', Array.from(newSelectedTypes));
    };

    const handleBrandChange = (brand) => {
        const newSelectedBrands = new Set(selectedBrands);
        if (newSelectedBrands.has(brand)) {
            newSelectedBrands.delete(brand);
        } else {
            newSelectedBrands.add(brand);
        }
        setSelectedBrands(newSelectedBrands);
        onChange('brand', Array.from(newSelectedBrands));
    };

    const handleCombainerChange = (combainer) => {
        const newSelectedCombainers = new Set(selectedCombainers);
        if (newSelectedCombainers.has(combainer)) {
            newSelectedCombainers.delete(combainer);
        } else {
            newSelectedCombainers.add(combainer);
        }
        setSelectedCombainers(newSelectedCombainers);
        onChange('combainer', Array.from(newSelectedCombainers));
    };

    const handleSprayerChange = (sprayer) => {
        const newSelectedSprayers = new Set(selectedSprayers);
        if (newSelectedSprayers.has(sprayer)) {
            newSelectedSprayers.delete(sprayer);
        } else {
            newSelectedSprayers.add(sprayer);
        }
        setSelectedSprayers(newSelectedSprayers);
        onChange('sprayer', Array.from(newSelectedSprayers));
    };

    return (
        <div {...storyblokEditable(blok)} className="filter-wrapper">
            <h3>{blok.wheels_type}</h3>
            {blok.wheels_system_type.map((type, index) => (
                <div key={index} className="custom-checkbox">
                    <input
                        type="checkbox"
                        id={`type-checkbox-${index}`}
                        value={type}
                        checked={selectedTypes.has(type)}
                        onChange={() => handleTypeChange(type)}
                    />
                    <label htmlFor={`type-checkbox-${index}`}>{type}</label>
                </div>
            ))}
            <hr className="filter-hr"/>
            <h3>{blok.brand}</h3>
            {blok.tracktor_brand.map((brand, index) => (
                <div key={index} className="custom-checkbox">
                    <input
                        type="checkbox"
                        id={`brand-checkbox-${index}`}
                        value={brand}
                        checked={selectedBrands.has(brand)}
                        onChange={() => handleBrandChange(brand)}
                    />
                    <label htmlFor={`brand-checkbox-${index}`}>{brand}</label>
                </div>
            ))}
            <hr className="filter-hr"/>
            <h3>{blok.combainer}</h3>
            {blok.model_combainer.map((combainer, index) => (
                <div key={index} className="custom-checkbox">
                    <input
                        type="checkbox"
                        id={`combainer-checkbox-${index}`}
                        value={combainer}
                        checked={selectedCombainers.has(combainer)}
                        onChange={() => handleCombainerChange(combainer)}
                    />
                    <label htmlFor={`combainer-checkbox-${index}`}>{combainer}</label>
                </div>
            ))}
            <hr className="filter-hr"/>
            <h3>{blok.sprayer}</h3>
            {blok.model_sprayer.map((sprayer, index) => (
                <div key={index} className="custom-checkbox">
                    <input
                        type="checkbox"
                        id={`sprayer-checkbox-${index}`}
                        value={sprayer}
                        checked={selectedSprayers.has(sprayer)}
                        onChange={() => handleSprayerChange(sprayer)}
                    />
                    <label htmlFor={`sprayer-checkbox-${index}`}>{sprayer}</label>
                </div>
            ))}
        </div>
    );
};

export default FilterWheels;
