import React from "react";
import { storyblokEditable } from "@storyblok/react";

const Image3d = ({ blok }) => {
    return (
        <div className="image3d-container" {...storyblokEditable(blok)}>
            <div className="image3d-text">{blok.name}</div>
            <div className="image3d-item">
                <img src={blok.imagewheel.filename} alt={blok.imagewheel.alt} />
            </div>
        </div>
    );
};

export default Image3d;
