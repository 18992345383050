import React from "react";
import { storyblokEditable } from "@storyblok/react";

const WheelsItem = ({ blok }) => {
    return (
        <div className="wheels-item-container" {...storyblokEditable(blok)}>
            <div className="wheels-item-img">
                {blok.tractor_img && <img src={blok.tractor_img.filename} alt={blok.tractor_img.alt}/>}
            </div>
            <div className="wheels-text">{blok.text_description}</div>
        </div>
    );
};

export default WheelsItem;
