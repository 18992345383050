import React from 'react';
import QRCode from 'qrcode.react';

const PDFLink = ({ fileName, linkText }) => {
    const pdfUrl = `/${fileName}`;

    return (
        <div className="pdf-file">
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer" className="pdf-file-link">
                {linkText}
            </a>
            <QRCode value={window.location.origin + pdfUrl} />
        </div>
    );
};

export default PDFLink;
