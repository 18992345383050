import React, {Suspense} from "react";
import { storyblokEditable } from "@storyblok/react";
import {Canvas} from "@react-three/fiber";
import {Environment, OrbitControls, useGLTF} from "@react-three/drei";


const ArtModel = ({ blok }) => {
    const Model = ({ path }) => {
        const { scene } = useGLTF(path);
        return <primitive object={scene} scale={0.012} />;
    };

    return (
        <section className="model-background" {...storyblokEditable(blok)}>
            <Suspense fallback={
                <div className="loader-wrapper">
                    <div className="loader"></div>
                </div>
            }>
                <Canvas camera={{position: [10, 0, 26.25], fov: 15 }} style={{width: "100vw", height: "100vh"}}>
                    <ambientLight intensity={0.5}/>
                    <OrbitControls
                        enableZoom={true}
                        maxDistance={35}
                        minDistance={10}
                        minPolarAngle={0}
                    />
                    <Model path="/model1.glb" />
                    <Environment preset="sunset"/>
                </Canvas>
            </Suspense>
        </section>
    );
};

export default ArtModel;
