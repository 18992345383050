import React from "react";
import { storyblokEditable } from "@storyblok/react";
import { cleanUrl } from "../utils/cleanUrl";

const ButtonLink = ({ blok }) => {
    return (
        <div {...storyblokEditable(blok)} className={`btn-wrapper`}>
            {blok && blok.button_link && (
                <a
                    href={cleanUrl(blok.button_link.cached_url)}
                    className={`btn-link`}
                    target={blok.target || "_self"}
                    rel={blok.target === "_blank" ? "noopener noreferrer" : ""}
                >
                    {blok.button_title}

                    <i className="link-icon" />
                </a>
            )}
        </div>
    );
};

export default ButtonLink;