import React from "react";
import { storyblokEditable } from "@storyblok/react";

const ProductsListItem = ({ blok }) => {
    return (
        <div className="products-list-item-container" {...storyblokEditable(blok)}>
            <div className="products-list-number">{blok.step}</div>
            <div className="products-list-text">{blok.text}</div>
            <div className="products-list-item-img">
                {blok.img && <img src={blok.img.filename} alt={blok.img.alt}/>}
            </div>
        </div>
    );
};

export default ProductsListItem;