import React from "react";
import { storyblokEditable } from "@storyblok/react";
import Teaser from "./Teaser";

const TracktorsGallery = ({ blok }) => {
    return (
        <section className="tracktors" {...storyblokEditable(blok)}>
            {blok.h2_subtitle && blok.h2_subtitle.map(title => (
                <Teaser key={title._uid} blok={title}/>
            ))}
            <div className="tracktors-gallery">
                {Object.keys(blok).filter(key => key.includes("tracktor")).map((key, index) => (
                    <img key={index} src={blok[key].filename} alt={blok[key].alt}/>
                ))}
            </div>
        </section>
    );
};

export default TracktorsGallery;