export const cleanUrl = (url) => {
    if (!url) return "#";

    const langPrefixes = ['de/', 'en/', 'es/', 'fr/', 'it/', 'pl/'];

    for (let prefix of langPrefixes) {
        if (url.startsWith(`/${prefix}`)) {
            return url.substring(prefix.length + 1);
        }
    }

    return url;
};