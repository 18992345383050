import React, { useState, useEffect } from "react";
import { storyblokEditable } from "@storyblok/react";
import Teaser from "./Teaser";

const Advantages = ({ blok }) => {
    const [isVideoInViewport, setIsVideoInViewport] = useState(false);

    const handleScroll = () => {
        const videoElement = document.querySelector(".advantage-media video");
        if (videoElement) {
            const rect = videoElement.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            setIsVideoInViewport(rect.top >= 0 && rect.bottom <= windowHeight);
        }
    };

    useEffect(() => {
        const videoElement = document.querySelector(".advantage-media video");
        if (videoElement) {
            window.addEventListener("scroll", handleScroll);
            handleScroll();

            return () => window.removeEventListener("scroll", handleScroll);
        }
    }, []);

    return (
        <section className="advantages" {...storyblokEditable(blok)}>
            {blok.h2_subtitle && blok.h2_subtitle.map(title => (
                <Teaser key={title._uid} blok={title}/>
            ))}
            <div className="advantages-wrapper">
                <div className="advantages-container">
                    <div className="advantage-item">
                        <div className="advantage-number">01</div>
                        <div className="advantage-text">{blok.advantage1}</div>
                    </div>
                    <div className="advantage-item">
                        <div className="advantage-number">02</div>
                        <div className="advantage-text">{blok.advantage2}</div>
                    </div>
                    <div className="advantage-item">
                        <div className="advantage-number">03</div>
                        <div className="advantage-text">{blok.advantage3}</div>
                    </div>
                </div>

                <div className="advantage-media">
                    {blok.img_video_file && blok.img_video_file.filename && (
                        blok.img_video_file.filename.endsWith('.mp4') ? (
                            <video autoPlay muted loop={isVideoInViewport}>
                                <source src={blok.img_video_file.filename} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <React.Fragment>
                                <div className="info-bolted-wrapper" style={{display: blok.boltedties ? 'flex' : 'none'}}>
                                    <div className="poligon-wrapper">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.42671 0.421485C9.40008 -0.140495 10.5993 -0.140495 11.5727 0.421485L17.5084 3.84848C18.4818 4.41046 19.0815 5.44904 19.0815 6.573V13.427C19.0815 14.551 18.4818 15.5895 17.5084 16.1515L11.5727 19.5785C10.5993 20.1405 9.40008 20.1405 8.42671 19.5785L2.49097 16.1515C1.51759 15.5895 0.917969 14.551 0.917969 13.427V6.573C0.917969 5.44904 1.51759 4.41046 2.49097 3.84848L8.42671 0.421485Z"
                                                fill="#252525"/>
                                            <path d="M9.99609 6.0918H10.0051" stroke="white" strokeWidth="2"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9.99609 9V13.539" stroke="white" strokeWidth="1.5"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className="bolted-text">
                                        <span>F1=F2=F3</span>
                                        <span>{blok.boltedties}</span>
                                    </div>
                                </div>
                                <img src={blok.img_video_file.filename} alt={blok.img_video_file.alt}/>
                            </React.Fragment>
                        )
                    )}
                </div>
            </div>
        </section>
    );
};

export default Advantages;
