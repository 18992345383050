import React from "react";
import { storyblokEditable } from "@storyblok/react";
import Teaser from "./Teaser";

const AudioSpeakers = ({ blok }) => {
    const volyaAudioLink = blok.volya_audio_link && blok.volya_audio_link.cached_url ? blok.volya_audio_link.cached_url : blok.volya_audio_link;

    return (
        <section className="audio" {...storyblokEditable(blok)}>
            <div className="audio-text">
                {blok.h2_subtitle && blok.h2_subtitle.map(title => (
                    <Teaser key={title._uid} blok={title}/>
                ))}
                <p>{blok.audio_text}</p>
                <h3 style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1rem',
                    marginBottom: '1rem'
                }}>{blok.volyaudio_title}</h3>
                <a href={volyaAudioLink} target="_blank" rel="noopener noreferrer" className="audio-link">{volyaAudioLink}</a>
            </div>
            <div className="audio-gallery">
            <div className="audio-speaker-1">
                    <img src={blok.speaker1.filename} alt={blok.speaker1.alt}/>
                </div>
                <div className="audio-speaker-2">
                    <img src={blok.speaker2.filename} alt={blok.speaker2.alt}/>
                </div>

                <div className="audio-speaker-3">
                    <img src={blok.speaker3.filename} alt={blok.speaker3.alt}/>
                </div>
            </div>
        </section>
    );
};

export default AudioSpeakers;