import React from "react";
import { storyblokEditable } from "@storyblok/react";

const PartnerItem = ({ blok }) => {
    return (
        <div className="partner-item-wrapper" {...storyblokEditable(blok)}>
            <div className="location-title">{blok.location_title}</div>
            <div>{blok.location_name}</div>
            <div className="brand-company-wrapper">
                <div className="brand-container">
                    <div className="brand-title">{blok.brand_title}</div>
                    <div>{blok.brand_name}</div>
                </div>
                <div className="company-container">
                    <div className="company-title">{blok.company_title}</div>
                    <div>{blok.company_name}</div>
                </div>
            </div>
        </div>
    );
};

export default PartnerItem;