import React, { useEffect, useState } from "react";
import { StoryblokComponent } from "@storyblok/react";
import { useLanguage } from "./contexts/LanguageContext";
import LoadingAnimation from "./components/LoadingAnimation";

function App() {
  const { language, setLanguage } = useLanguage();
  const [story, setStory] = useState(null);
  let slug = window.location.pathname === "/" ? "home" : window.location.pathname.replace("/", "");

  useEffect(() => {
    const fetchData = async () => {
      const url = `https://api.storyblok.com/v2/cdn/stories/${slug}?version=draft&token=${process.env.REACT_APP_STORYBLOK_TOKEN}&language=${language}`;
      const response = await fetch(url);
      const data = await response.json();
      setStory(data.story);
    };

    fetchData();
  }, [slug, language]);

  if (!story) {
    return <LoadingAnimation />;
  }

  return <StoryblokComponent blok={story.content}/>;
}

export default App;
