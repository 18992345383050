import React from "react";
import { storyblokEditable } from "@storyblok/react";

const ServiceItem = ({ blok }) => {
    return (
        <div className="service-item-container" {...storyblokEditable(blok)}>
            <div className="service-number">{blok.step}</div>
            <div className="service-text">{blok.text}</div>
            <div className="service-item-img">
                {blok.service_img && <img src={blok.service_img.filename} alt={blok.service_img.alt}/>}
            </div>
        </div>
    );
};

export default ServiceItem;
