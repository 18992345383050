import React from "react";
import { storyblokEditable } from "@storyblok/react";
import PDFLink from "./PDFLink";

const TextSeo = ({ blok }) => {
    const textContent = blok.text.content.map(block =>
        block.content.map(textBlock => textBlock.text).join('')
    ).join(' ');

    return (
        <section className="text-seo" {...storyblokEditable(blok)}>
            <h3>{blok.text_title}</h3>
            <p>{textContent}</p>
            <PDFLink fileName="Catalog_Volya_may_2024.pdf"
                     linkText="To open PDF catalog you could use this link or use the QR code below"/>
        </section>
    );
};

export default TextSeo;
