import React from "react";
import { storyblokEditable } from "@storyblok/react";
import { cleanUrl } from "../utils/cleanUrl";

const WheelsLink = ({ blok }) => {
    return (
        <section className="wheels-link-wrapper">
            <a href={cleanUrl(blok.whells_link.cached_url)} className="wheels-link-container" {...storyblokEditable(blok)}>
                <h2 className="wheels-link-text">{blok.text_link}</h2>
                <div className="wheels-link-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_256_206)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M19.2477 4.75229C19.3883 4.89295 19.4673 5.08371 19.4673 5.28262L19.4673 15.1821C19.4673 15.5963 19.1315 15.9321 18.7173 15.9321C18.3031 15.9321 17.9673 15.5963 17.9673 15.1821V7.09328L5.81263 19.248C5.51973 19.5409 5.04486 19.5409 4.75197 19.248C4.45907 18.9551 4.45907 18.4802 4.75197 18.1873L16.9067 6.03262L8.81783 6.03262C8.40362 6.03262 8.06783 5.69684 8.06783 5.28262C8.06783 4.86841 8.40362 4.53262 8.81783 4.53262L18.7173 4.53262C18.9162 4.53262 19.107 4.61164 19.2477 4.75229Z"
                                  fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_256_206">
                                <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 -1 24 24)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </a>
            <div className="link-img">
                {blok.wheel_img && <img src={blok.wheel_img.filename} alt={blok.wheel_img.alt}/>}
            </div>
        </section>
    );
};

export default WheelsLink;
