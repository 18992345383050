import React from "react";
import { storyblokEditable } from "@storyblok/react";
import RichTextField from "./RichTextField";

const LifeItem = ({ blok, onClick }) => {
    return (
        <div className="life-item-container" {...storyblokEditable(blok)} onClick={onClick}>
            <div className="life-item-img">
                {blok.life_img && <img src={blok.life_img.filename} alt={blok.life_img.alt} />}
            </div>
            <div className="life-text">
                {blok.life_text}
            </div>
            <div className="life-event" style={{ display: 'none' }}>
                {blok.life_event && <RichTextField data={blok.life_event} />}
            </div>
        </div>
    );
};

export default LifeItem;
