import React from "react";
import { storyblokEditable } from "@storyblok/react";
import Teaser from "./Teaser";
import ServiceItem from "./Service-item";

const Services = ({ blok }) => {
    return (
        <section className="services" {...storyblokEditable(blok)}>
            {blok.h2_subtitle && blok.h2_subtitle.map(title => (
                <Teaser key={title._uid} blok={title}/>
            ))}
            <div className="services-container">
                {Object.keys(blok).map(key => {
                    if (key.startsWith("service_item_")) {
                        return blok[key].map(item => (
                            <ServiceItem key={item._uid} blok={item} />
                        ));
                    }
                    return null;
                })}
            </div>
        </section>
    );
};

export default Services;

