import React, { useState } from 'react';
import { storyblokEditable } from "@storyblok/react";
import Title from "./Title";
import { useLanguage } from "../contexts/LanguageContext";
import { cleanUrl } from "../utils/cleanUrl";


const HeaderWheels = ({ blok }) => {
    const [openMenu, setOpenMenu] = useState(false);
    const { language, setLanguage } = useLanguage();

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    return (
        <header style={{
            backgroundColor: '#000000'
        }}>
            <div className="header-wrapper-wheels">
                <nav {...storyblokEditable(blok)} className={`nav-wrapper`}>
                    <div className="nav-links">
                        {blok && blok.nav_link_home && (
                            <a href={cleanUrl(blok.nav_link_home.cached_url)}>{blok.home_link_name}</a>
                        )}
                    </div>
                    <div className="nav-links">
                        {blok && blok.nav_link_about_us && (
                            <a href={cleanUrl(blok.nav_link_about_us.cached_url)}>{blok.about_link_name}</a>
                        )}
                    </div>
                    <div className="nav-links">
                        {blok && blok.nav_link_production && (
                            <a href={cleanUrl(blok.nav_link_production.cached_url)}>{blok.production_link_name}</a>
                        )}
                    </div>
                    <div>
                        {blok && blok.logo && (
                            <a href={cleanUrl(blok.nav_link_home.cached_url)}><img src={blok.logo.filename} alt="Logo"/></a>
                        )}
                    </div>
                    <div className="nav-links">
                        {blok && blok.nav_link_wheels && (
                            <a href={cleanUrl(blok.nav_link_wheels.cached_url)}>{blok.wheels_link_name}</a>
                        )}
                    </div>
                    <div className="nav-links">
                        {blok && blok.nav_link_contacts && (
                            <a href={cleanUrl(blok.nav_link_contacts.cached_url)}>{blok.contacts_link_name}</a>
                        )}
                    </div>
                    {/* Выбор языка */}
                    <div className="nav-links">
                        <select className="lang-switcher" value={language} onChange={handleLanguageChange}>
                            <option value="de">DE</option>
                            <option value="en">EN</option>
                            <option value="es">ES</option>
                            <option value="fr">FR</option>
                            <option value="it">IT</option>
                            <option value="pl">PL</option>
                        </select>
                    </div>
                    {/* Мобильное меню */}
                    <div className='mobile-menu-wrapper'>
                        <button className="btn-mobile-menu" onClick={() => setOpenMenu(!openMenu)}>
                            <i className="icon-menu"/>
                            <i className="icon-menu"/>
                            <i className="icon-menu"/>
                        </button>
                        {openMenu && (
                            <ul className="mobile-menu-links">
                                <li><a href={cleanUrl(blok.nav_link_home.cached_url)}>{blok.home_link_name}</a></li>
                                <li><a href={cleanUrl(blok.nav_link_about_us.cached_url)}>{blok.about_link_name}</a></li>
                                <li><a href={cleanUrl(blok.nav_link_production.cached_url)}>{blok.production_link_name}</a></li>
                                <li><a href={cleanUrl(blok.nav_link_wheels.cached_url)}>{blok.wheels_link_name}</a></li>
                                <li><a href={cleanUrl(blok.nav_link_contacts.cached_url)}>{blok.contacts_link_name}</a></li>
                                <li>
                                    <select className="lang-switcher"
                                            value={language}
                                            onChange={handleLanguageChange}
                                    >
                                        <option value="de">DE</option>
                                        <option value="en">EN</option>
                                        <option value="es">ES</option>
                                        <option value="fr">FR</option>
                                        <option value="it">IT</option>
                                        <option value="pl">PL</option>
                                    </select>
                                </li>
                            </ul>
                        )}
                    </div>
                </nav>

                <div className="title-wrapper-wheels">
                    {blok.h1_title && blok.h1_title.map(title => (
                        <Title key={title._uid} blok={title}/>
                    ))}
                </div>
            </div>
        </header>
    );
};

export default HeaderWheels;
