import React from 'react';
import { storyblokEditable } from "@storyblok/react";

const Title = ({ blok }) => {

    return (
        <div {...storyblokEditable(blok)}>
            {blok && blok.subheadline && <p className='subtext'>{blok.subheadline}</p>}
            {blok && blok.title && <h1>{blok.title}</h1>}
            {blok && blok.subheadline_text && <p className='subtext-underheader'>{blok.subheadline_text}</p>}
        </div>
    );
};

export default Title;