import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { storyblokEditable } from "@storyblok/react";
import emailjs from '@emailjs/browser';
import Select from 'react-select';


const spaceId = process.env.REACT_APP_STORYBLOK_SPACE_ID;
const assetToken = process.env.REACT_APP_STORYBLOK_ASSET_TOKEN;
function OrderForm({ blok, closeModal }) {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        stock_front: '',
        stock_back: '',
        desire_front: '',
        desire_back: '',
        product_type: '',
        machinery: '',
        machinery_model: '',
        wheels_type: '',
        formula: '',
        spacing: '',
        photo: null,
        desire_size: '',
        specific_info: '',
    });

    const [errors, setErrors] = useState({});
    const [consent, setConsent] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [machineryOptions, setMachineryOptions] = useState([]);
    const [wheelFormulaOptions, setWheelFormulaOptions] = useState([]);
    const [wheelsTypeOptions, setWheelsTypeOptions] = useState([]);

    useEffect(() => {
        const fetchMachineryOptions = async () => {
            try {
                const response = await fetch(
                    `https://api.storyblok.com/v2/cdn/datasource_entries?datasource=machinery-models&token=${process.env.REACT_APP_STORYBLOK_PUBLIC_TOKEN}`
                );
                const data = await response.json();
                const options = data.datasource_entries.map((entry) => ({
                    value: entry.value,
                    label: entry.name,
                }));

                setMachineryOptions(options);
            } catch (error) {
                console.error("Error fetching country options:", error);
            }
        };

        const fetchWheelFormulaOptions = async () => {
            try {
                const response = await fetch(
                    `https://api.storyblok.com/v2/cdn/datasource_entries?datasource=wheel-formula&token=${process.env.REACT_APP_STORYBLOK_PUBLIC_TOKEN}`
                );
                const data = await response.json();
                const options = data.datasource_entries.map((entry) => ({
                    value: entry.value,
                    label: entry.name,
                }));

                setWheelFormulaOptions(options);
            } catch (error) {
                console.error("Error fetching wheel formula options:", error);
            }
        };

        const fetchWheelsTypeOptions = async () => {
            try {
                const response = await fetch(
                    `https://api.storyblok.com/v2/cdn/datasource_entries?datasource=wheels-type&token=${process.env.REACT_APP_STORYBLOK_PUBLIC_TOKEN}`
                );
                const data = await response.json();
                const options = data.datasource_entries.map((entry) => ({
                    value: entry.value,
                    label: entry.name,
                }));

                setWheelsTypeOptions(options);
            } catch (error) {
                console.error("Error fetching wheels type options:", error);
            }
        };

        fetchMachineryOptions();
        fetchWheelFormulaOptions();
        fetchWheelsTypeOptions();
    }, []);

    const handleImageChange = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
            setFormData({ ...formData, photo: file }); // Store the file object itself
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleChangeSelect = (selectedOption, { name }) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: selectedOption ? selectedOption.value && selectedOption.label : null
        }));
    };

    const handleMachineryChange = (selectedOption) => {
        setFormData(prevState => ({
            ...prevState,
            machinery: selectedOption ? selectedOption.value : ''
        }));
    };

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        let newValue = value;

        if (name === 'phone') {
            newValue = value.replace(/[^\d\s+()-]/g, '');
            setFormData({ ...formData, [name]: newValue });
        }

        if (type === "checkbox") {
            setConsent(checked);
        }
        else if (type === "file") {
            setFormData({ ...formData, [name]: files[0] });
        } else if (type === "radio") {
            setFormData({ ...formData, [name]: value });
        } else {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        }

        setErrors({ ...errors, [name]: "" });
    };

    const validateForm = () => {
        let formIsValid = true;
        let newErrors = {};

        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Invalid email address';
            formIsValid = false;
        }

        if (!consent) {
            newErrors.consent = 'You must agree to the processing of personal data';
            formIsValid = false;
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const resetForm = () => {
        setFormData({
            name: '',
            phone: '',
            email: '',
            stock_front: '',
            stock_back: '',
            desire_front: '',
            desire_back: '',
            product_type: '',
            machinery: '',
            machinery_model: '',
            wheels_type: '',
            formula: '',
            spacing: '',
            photo: null,
            desire_size: '',
            specific_info: '',
        });

        setImagePreviewUrl('');
        setConsent(false);
        setErrors({});
        setSubmitting(false);
    };

    const fileUpload = async (signedRequest, file) => {
        const formData = new FormData();
        for (const key in signedRequest.fields) {
            formData.append(key, signedRequest.fields[key]);
        }
        formData.append('file', file);

        const response = await axios.post(signedRequest.post_url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        if (response.status === 204) {
            // Perform the third step: finalizing the upload
            await axios.get(
                `https://api.storyblok.com/v1/spaces/${spaceId}/assets/${signedRequest.id}/finish_upload`,
                {
                    headers: {
                        'Authorization': `${assetToken}`
                    }
                }
            );

            return `https://a.storyblok.com/${signedRequest.fields.key}`;
        } else {
            throw new Error('File upload failed');
        }
    };

    const signAsset = async (file) => {
        try {
            const response = await axios.post(
                `https://api.storyblok.com/v1/spaces/${spaceId}/assets`,
                {
                    filename: `${formData.email}_${file.name}`,
                    size: "500x500",
                    asset_folder_id: 564244,
                    validate_upload: 1
                },
                {
                    headers: {
                        'Authorization': `${assetToken}`,
                        'Content-Type': 'application/json'
                    }

                }
            )

            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Failed to sign asset');
            }
        } catch (error) {
            if (error.response) {
                console.error('API response error:', error.response.data);
            } else {
                console.error('Network or other error:', error.message);
            }
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        setSubmitting(true);

        try {
            let uploadedFileUrl = null;
            if (formData.photo) {
                const signedRequest = await signAsset(formData.photo);
                uploadedFileUrl = await fileUpload(signedRequest, formData.photo);
            }

            const form = e.target;
            let photoUrlInput = form.querySelector('input[name="photo_url"]');

            if (!photoUrlInput) {
                photoUrlInput = document.createElement('input');
                photoUrlInput.type = 'hidden';
                photoUrlInput.name = 'photo_url';
                form.appendChild(photoUrlInput);
            }

            photoUrlInput.value = uploadedFileUrl || '';

            const photoInput = form.querySelector('input[name="photo"]');
            if (photoInput) {
                photoInput.remove();
            }

            await emailjs.sendForm(
                'service_sez340g',
                'template_jqj11pq',
                form,
                'wFK9F-KQXmgY_LjA0'
            );

            resetForm();
            closeModal();
        } catch (error) {
            console.error('Error during form submission:', error);
            setSubmitting(false);
        }
    };

    return (
        <form {...storyblokEditable(blok)} onSubmit={handleSubmit} className="order-form">
            <div className="order-credentials">
                <div className="contact-input">
                    <label htmlFor="name">{blok.name}</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder={blok.name}
                    />
                    {errors.name && <p style={{color: 'red'}}>{errors.name}</p>}
                </div>
                <div className="contact-input">
                    <label htmlFor="phone">{blok.phone}</label>
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder={blok.phone}
                    />
                    {errors.phone && <p style={{color: 'red'}}>{errors.phone}</p>}
                </div>
                <div className="contact-input">
                    <label htmlFor="email">{blok.email}</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={blok.email}
                    />
                    {errors.email && <p style={{color: 'red'}}>{errors.email}</p>}
                </div>
            </div>
            <hr/>
            <div className="order-product-type">
                <h3>{blok.type}</h3>
                <div className="order-select">
                    <div className="order-select-radio">
                        <input type="radio" id="wheels" name="product_type" value={blok.product_type_wheels}/>
                        <label htmlFor="wheels">{blok.product_type_wheels}</label>
                    </div>
                    <div className="order-select-radio">
                        <input type="radio" id="spacer" name="product_type" value={blok.product_type_spacer}/>
                        <label htmlFor="spacer">{blok.product_type_spacer}</label>
                    </div>
                    <div className="order-select-radio">
                        <input type="radio" id="cargo" name="product_type" value={blok.product_type_cargo}/>
                        <label htmlFor="cargo">{blok.product_type_cargo}</label>
                    </div>
                    <div className="order-select-radio">
                        <input type="radio" id="art" name="product_type" value={blok.product_type_art}/>
                        <label htmlFor="art">{blok.product_type_art}</label>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="order-select-item">
                <label htmlFor="machinery-select">{blok.machinery_label}</label>
                <Select
                    isClearable
                    id="machinery-select"
                    options={machineryOptions}
                    className="custom-select-container"
                    classNamePrefix="custom-select"
                    value={machineryOptions.find(option => option.value === formData.machinery)}
                    onChange={handleMachineryChange}
                />
            </div>
            <div className="order-select-item contact-input">
                <label htmlFor="machinery_model">{blok.machinery_model_label}</label>
                <input
                    id="machinery_model"
                    type="text"
                    name="machinery_model"
                    value={formData.machinery_model}
                    onChange={handleChange}
                    placeholder={blok.machinery_model_label}
                />
                {errors.machinery_model && <p style={{color: 'red'}}>{errors.machinery_model}</p>}
            </div>
            <div className="order-select-item">
                <label htmlFor="wheels-type-select">{blok.wheels_type_label}</label>
                <Select
                    isClearable
                    id="wheels-type-select"
                    options={wheelsTypeOptions}
                    className="custom-select-container"
                    classNamePrefix="custom-select"
                    value={wheelsTypeOptions.find(option =>
                        option.value === formData.wheels_type || ''
                    )}
                    onChange={option => handleChangeSelect(option, {name: 'wheels_type'})}
                />
            </div>
            <div className="order-select-item">
                <label htmlFor="formula-select">{blok.formula_label}</label>
                <Select
                    isClearable
                    id="formula-select"
                    options={wheelFormulaOptions}
                    className="custom-select-container"
                    classNamePrefix="custom-select"
                    value={wheelFormulaOptions.find(option =>
                        option.value === formData.formula || ''
                    )}
                    onChange={option => handleChangeSelect(option, {name: 'formula'})}
                />
            </div>
            <div className="order-select-item contact-input">
                <label htmlFor="spacing-select">{blok.spacing_label}</label>
                <input
                    id="spacing-select"
                    type="text"
                    name="spacing"
                    value={formData.spacing}
                    onChange={handleChange}
                    placeholder={blok.spacing_label}
                />
            </div>
            <input type="hidden" name="machinery" value={formData.machinery}/>
            <input type="hidden" name="wheels_type" value={formData.wheels_type}/>
            <input type="hidden" name="formula" value={formData.formula}/>
            <hr/>
            <div className="stock-size">
                <div className="stock-label">{blok.stock_label}</div>
                <div className="stock-wrapper">
                    <div className="contact-input">
                        <label htmlFor="stock_front">{blok.front}</label>
                        <input
                            type="text"
                            name="stock_front"
                            value={formData.stock_front}
                            onChange={handleChange}
                        />
                        {errors.stock_front && <p style={{color: 'red'}}>{errors.stock_front}</p>}
                    </div>
                    <div className="contact-input">
                        <label htmlFor="stock_back">{blok.back}</label>
                        <input
                            type="text"
                            name="stock_back"
                            value={formData.stock_back}
                            onChange={handleChange}
                        />
                        {errors.stock_back && <p style={{color: 'red'}}>{errors.stock_back}</p>}
                    </div>
                </div>
            </div>
            <hr/>
            <div className="desire">
                <div className="desire-label">{blok.desired_label}</div>
                <div className="desire-wrapper">
                    <div className="desire-radio">
                        <input type="radio" id="standart" name="size_type" value={blok.standart_size}/>
                        <label htmlFor="standart">{blok.standart_size}</label>

                        <input type="radio" id="pick_up" name="size_type" value={blok.pickup_size}/>
                        <label htmlFor="pick_up">{blok.pickup_size}</label>
                    </div>
                    <div className="desire-input">
                        <div className="contact-input">
                            <label htmlFor="desire_front">{blok.front}</label>
                            <input
                                type="text"
                                name="desire_front"
                                value={formData.desire_front}
                                onChange={handleChange}
                            />
                            {errors.desire_front && <p style={{color: 'red'}}>{errors.desire_front}</p>}
                        </div>
                        <div className="contact-input">
                            <label htmlFor="desire_back">{blok.back}</label>
                            <input
                                type="text"
                                name="desire_back"
                                value={formData.desire_back}
                                onChange={handleChange}
                            />
                            {errors.desire_back && <p style={{color: 'red'}}>{errors.desire_back}</p>}
                        </div>
                        <div className="contact-text">
                            <label htmlFor="desire_size">{blok.size_criteria}</label>
                            <textarea
                                name="desire_size"
                                value={formData.desire_size}
                                onChange={handleChange}
                            />
                            {errors.size_criteria && <p style={{color: 'red'}}>{errors.size_criteria}</p>}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="specific-info">
                <div className="specific-tracktor-container">
                    <label htmlFor="photo-upload">{blok.tracktor_photo}</label>
                    <div className="specific-tracktor">
                        <input
                            type="file"
                            id="photo-upload"
                            name="photo"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                        {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview"/>}
                    </div>
                </div>
                <div className="specific-info-text contact-text">
                    <label htmlFor="specific_info">{blok.additional_info}</label>
                    <textarea
                        name="specific_info"
                        value={formData.specific_info}
                        onChange={handleChange}
                    />
                    {errors.specific_info && <p style={{color: 'red'}}>{errors.specific_info}</p>}
                </div>
                <div className="specific-check">
                    <input
                        type="checkbox"
                        id="consent"
                        name="consent"
                        checked={consent}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="consent">{blok.processing_data}</label>
                    {errors.consent && <div style={{color: 'red'}}>{errors.consent}</div>}
                </div>
            </div>
            <div className="order-btn-container">
                <button type="submit" className="contact-submit" disabled={submitting}>
                    {submitting ? 'Sending...' : blok.send_button}
                    <i className="link-icon"/>
                </button>
            </div>
        </form>
    );
}

export default OrderForm;
