import React from "react";
import { storyblokEditable } from "@storyblok/react";
import Teaser from "./Teaser";
import Stage from "./Stage-production";

const Production = ({ blok }) => {
    return (
        <section className="production-container" {...storyblokEditable(blok)}>
            {blok.h2_subtitle && blok.h2_subtitle.map(title => (
                <Teaser key={title._uid} blok={title}/>
            ))}
            <div className="production-stage-wrapper">
                {blok.stage_1 && blok.stage_1.map(stage => (
                    <Stage key={stage._uid} blok={stage} />
                ))}
                {blok.stage_2 && blok.stage_2.map(stage => (
                    <Stage key={stage._uid} blok={stage} />
                ))}
                {blok.stage_3 && blok.stage_3.map(stage => (
                    <Stage key={stage._uid} blok={stage} />
                ))}
                {blok.stage_4 && blok.stage_4.map(stage => (
                    <Stage key={stage._uid} blok={stage} />
                ))}
                {blok.stage_5 && blok.stage_5.map(stage => (
                    <Stage key={stage._uid} blok={stage} />
                ))}
                {blok.stage_6 && blok.stage_6.map(stage => (
                    <Stage key={stage._uid} blok={stage} />
                ))}
                {blok.stage_7 && blok.stage_7.map(stage => (
                    <Stage key={stage._uid} blok={stage} />
                ))}
            </div>
        </section>
    );
};

export default Production;
