import React from "react";
import '../App.css'; // Импортируйте стили

const LoadingAnimation = () => {
    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <div className="loading-text">Loading...</div>
            </div>
        </>

    );
};

export default LoadingAnimation;
