import React from "react";
import { render, NODE_IMAGE } from 'storyblok-rich-text-react-renderer';

const RichTextFieldNoImages = ({ data }) => {
    const options = {
        nodeResolvers: {
            [NODE_IMAGE]: () => null
        }
    };

    return <div>{render(data, options)}</div>;
};

export default RichTextFieldNoImages;

