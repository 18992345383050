import React, { useState, useEffect } from "react";
import { storyblokEditable } from "@storyblok/react";
import Teaser from "./Teaser";
import PartnerItem from "./PartnerItem";
import Select from "react-select";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const Map = ({ blok }) => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryOptions, setCountryOptions] = useState([]);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_STORYBLOK_GOOGLE_MAP_API_KEY}`
    });
    const [partnerMarkers, setPartnerMarkers] = useState([]);


    useEffect(() => {
        const fetchCountryOptions = async () => {
            try {
                const response = await fetch(
                    `https://api.storyblok.com/v2/cdn/datasource_entries?datasource=country&token=${process.env.REACT_APP_STORYBLOK_PUBLIC_TOKEN}`
                );
                const data = await response.json();
                const options = data.datasource_entries.map((entry) => ({
                    value: entry.value,
                    label: entry.name,
                }));

                setCountryOptions(options);
            } catch (error) {
                console.error("Error fetching country options:", error);
            }
        };

        fetchCountryOptions();
    }, []);

    const handleChange = async (selectedOption) => {
        setSelectedCountry(selectedOption ? selectedOption.value : "");

        const filteredPartners = blok.partner_item.filter(
            (partner) => partner.country_hidden_field === selectedOption.value
        );

        const markers = [];
        for (const partner of filteredPartners) {
            const address = partner.location_name; // Используем partner.location_name вместо partner.address, partner.city и partner.country_hidden_field
            try {
                const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                        address
                    )}&key=${process.env.REACT_APP_STORYBLOK_GOOGLE_MAP_API_KEY}`
                );
                const data = await response.json();
                console.log(data);
                if (data.results && data.results.length > 0) { // Проверяем наличие результатов геокодирования
                    const location = data.results[0].geometry.location;
                    markers.push({
                        lat: location.lat,
                        lng: location.lng,
                        name: partner.location_title
                    });
                } else {
                    console.error("Error fetching geolocation: No results found");
                }
            } catch (error) {
                console.error("Error fetching geolocation:", error);
            }
        }
        setPartnerMarkers(markers);
    };

    return (
        <section className="map" {...storyblokEditable(blok)}>
            {blok.h2_subtitle &&
                blok.h2_subtitle.map((title) => <Teaser key={title._uid} blok={title} />)}
            <div className="map-container">
                <div className="partners-wrapper" {...storyblokEditable(blok)}>
                    <div className="select-country-container">
                        <h3>{blok.select_country}</h3>
                        <Select
                            isClearable
                            id="select-country"
                            options={countryOptions}
                            className="custom-select-container"
                            classNamePrefix="custom-select"
                            value={countryOptions.find(
                                (option) => option.value === selectedCountry
                            )}
                            onChange={handleChange}
                        />
                        <div className="partner-list">
                            {selectedCountry &&
                                blok.partner_item
                                    .filter(
                                        (partner) => partner.country_hidden_field === selectedCountry
                                    )
                                    .map((partner) => (
                                        <PartnerItem key={partner._uid} blok={partner} />
                                    ))}
                        </div>
                    </div>
                </div>
                <div className="map-item-wrapper">
                    {isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '550px', borderRadius: '16px' }}
                            center={{ lat: 49.357130, lng: 7.271100 }}
                            zoom={5}
                            options={{
                                styles: [
                                    {
                                        "featureType": "administrative.country",
                                        "elementType": "geometry.stroke",
                                        "stylers": [
                                            {
                                                "color": "#ff0000"
                                            }
                                        ]
                                    }
                                ]
                            }}
                        >
                            {partnerMarkers.map((marker, index) => (
                                <Marker
                                    key={index}
                                    mapId="7389cef2332acc5b" // Map ID is required for advanced markers.
                                    position={{ lat: marker.lat, lng: marker.lng }}
                                    title={marker.name}
                                />
                            ))}
                        </GoogleMap>
                    ) : null}
                </div>
            </div>
        </section>
    );
};

export default Map;
