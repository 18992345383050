import React from "react";
import { storyblokEditable } from "@storyblok/react";

const ContactsPage = ({ blok }) => {
    return (
        <section className="contacts-container" {...storyblokEditable(blok)}>
            <div className="contacts-wrapper">
                <div className="contacts-item">
                    <div className="contacts-item-title">{blok.headquarters_title}</div>
                    <div className="contacts-item-text">{blok.headquarters_text}</div>
                </div>
                <div className="contacts-item">
                    <div className="contacts-item-title">{blok.manufacture_title}</div>
                    <div className="contacts-item-text">{blok.manufacture_text}</div>
                </div>
                <div className="contacts-item">
                    <div className="contacts-item-title">Email</div>
                    <div className="contacts-item-text">{blok.email}</div>
                </div>
            </div>
        </section>
    );
};

export default ContactsPage;
