import React from "react";
import { storyblokEditable } from "@storyblok/react";

const Stage = ({ blok }) => {
    return (
        <div className="stage-wrapper" {...storyblokEditable(blok)}>
            <div className="stage-text-container">
                <div className="stage-number">{blok.stage_number}</div>
                <div className="stage-text-block-wrapper">
                    <h2>{blok.stage_title}</h2>
                    <div className="stage-text">{blok.stage_explanation}</div>
                </div>
            </div>
            <img src={blok.stage_process.filename} alt={blok.stage_process.alt}/>
        </div>
    );
};

export default Stage;