import React from "react";
import { storyblokEditable } from "@storyblok/react";
import { cleanUrl } from "../utils/cleanUrl";

const MaterialsItem = ({ blok }) => {
    const pdfUrl = blok.pdf_file ? cleanUrl(blok.pdf_file.filename) : "#";

    return (
        <a href={pdfUrl} className="materials-item-container" target="_blank" rel="noopener noreferrer" {...storyblokEditable(blok)}>
            <div className="materials-item-img">
                {blok.image && <img src={blok.image.filename} alt={blok.image.alt}/>}
            </div>
            <div className="materials-text-wrapper">
                <div className="materials-date">{blok.date}</div>
                <div className="materials-title">{blok.materials_title}</div>
                <div className="materials-text">{blok.materials_shorttext}</div>
            </div>
        </a>
    );
};

export default MaterialsItem;
