import React from "react";
import { storyblokEditable } from "@storyblok/react";
import Teaser from "./Teaser";
import Image3d from "./Image3d";

const Gallery3D = ({ blok }) => {
    const handleClick = (url) => {
        window.open(url, "_blank");
    };

    return (
        <section className="gallery-3d" {...storyblokEditable(blok)}>
            {blok.h2_subheadline && blok.h2_subheadline.map(title => (
                <Teaser key={title._uid} blok={title} />
            ))}

            <div className="gallery-container">
                {blok.artwheels && blok.artwheels.map(item => (
                    <div key={item._uid} className="gallery-3d-item">
                        <Image3d key={item._uid} blok={item} />
                        <button
                            className="image3d-button"
                            onClick={() => handleClick("/art-wheel")}
                        >
                            {blok.viewbuttontext}
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                                <path
                                    d="M12.2448 12.7962L8.10829 8.65408C7.30797 9.28289 6.3186 9.62181 5.30135 9.61564C3.95919 9.61564 2.82385 9.15039 1.89461 8.21989C0.963884 7.28939 0.5 6.15253 0.5 4.80782C0.5 3.46459 0.964623 2.32698 1.89461 1.39575C2.82385 0.465249 3.95919 0 5.30135 0C6.64277 0 7.77884 0.465989 8.70882 1.39575C9.63807 2.32772 10.102 3.46459 10.102 4.80782C10.1081 5.82645 9.76965 6.81715 9.14168 7.61855L13.2974 11.7792C13.3634 11.8441 13.4154 11.922 13.4502 12.0078C13.485 12.0937 13.5019 12.1858 13.4998 12.2784C13.4998 12.4759 13.426 12.6483 13.2782 12.7962C13.2105 12.8636 13.1296 12.9165 13.0407 12.9514C12.9518 12.9864 12.8566 13.0028 12.7612 12.9996C12.6657 13.0028 12.5705 12.9864 12.4816 12.9514C12.3927 12.9165 12.3126 12.8636 12.2448 12.7962ZM5.30135 8.13631C6.22468 8.13631 7.00915 7.81308 7.65622 7.16587C8.30182 6.51866 8.62462 5.7324 8.62462 4.80782C8.62462 3.88324 8.30182 3.09772 7.65548 2.44977C7.00915 1.8033 6.22394 1.47933 5.30135 1.47933C4.37801 1.47933 3.59281 1.8033 2.94647 2.44977C2.30014 3.09772 1.97734 3.88324 1.97734 4.80782C1.97734 5.7324 2.30014 6.51866 2.94647 7.16587C3.59281 7.81308 4.37801 8.13631 5.30135 8.13631Z"
                                    fill="#CBCBCB" />
                            </svg>
                        </button>
                    </div>
                ))}
                {blok.dualwheels && blok.dualwheels.map(item => (
                    <div key={item._uid} className="gallery-3d-item">
                        <Image3d key={item._uid} blok={item} />
                        <button
                            className="image3d-button"
                            onClick={() => handleClick("/dual-standard-wheel")}
                        >
                            {blok.viewbuttontext}
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                                <path
                                    d="M12.2448 12.7962L8.10829 8.65408C7.30797 9.28289 6.3186 9.62181 5.30135 9.61564C3.95919 9.61564 2.82385 9.15039 1.89461 8.21989C0.963884 7.28939 0.5 6.15253 0.5 4.80782C0.5 3.46459 0.964623 2.32698 1.89461 1.39575C2.82385 0.465249 3.95919 0 5.30135 0C6.64277 0 7.77884 0.465989 8.70882 1.39575C9.63807 2.32772 10.102 3.46459 10.102 4.80782C10.1081 5.82645 9.76965 6.81715 9.14168 7.61855L13.2974 11.7792C13.3634 11.8441 13.4154 11.922 13.4502 12.0078C13.485 12.0937 13.5019 12.1858 13.4998 12.2784C13.4998 12.4759 13.426 12.6483 13.2782 12.7962C13.2105 12.8636 13.1296 12.9165 13.0407 12.9514C12.9518 12.9864 12.8566 13.0028 12.7612 12.9996C12.6657 13.0028 12.5705 12.9864 12.4816 12.9514C12.3927 12.9165 12.3126 12.8636 12.2448 12.7962ZM5.30135 8.13631C6.22468 8.13631 7.00915 7.81308 7.65622 7.16587C8.30182 6.51866 8.62462 5.7324 8.62462 4.80782C8.62462 3.88324 8.30182 3.09772 7.65548 2.44977C7.00915 1.8033 6.22394 1.47933 5.30135 1.47933C4.37801 1.47933 3.59281 1.8033 2.94647 2.44977C2.30014 3.09772 1.97734 3.88324 1.97734 4.80782C1.97734 5.7324 2.30014 6.51866 2.94647 7.16587C3.59281 7.81308 4.37801 8.13631 5.30135 8.13631Z"
                                    fill="#CBCBCB" />
                            </svg>
                        </button>
                    </div>
                ))}
                {blok.narrowwheels && blok.narrowwheels.map(item => (
                    <div key={item._uid} className="gallery-3d-item">
                        <Image3d key={item._uid} blok={item} />
                        <button
                            className="image3d-button"
                            onClick={() => handleClick("/narrowheel")}
                        >
                            {blok.viewbuttontext}
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                                <path
                                    d="M12.2448 12.7962L8.10829 8.65408C7.30797 9.28289 6.3186 9.62181 5.30135 9.61564C3.95919 9.61564 2.82385 9.15039 1.89461 8.21989C0.963884 7.28939 0.5 6.15253 0.5 4.80782C0.5 3.46459 0.964623 2.32698 1.89461 1.39575C2.82385 0.465249 3.95919 0 5.30135 0C6.64277 0 7.77884 0.465989 8.70882 1.39575C9.63807 2.32772 10.102 3.46459 10.102 4.80782C10.1081 5.82645 9.76965 6.81715 9.14168 7.61855L13.2974 11.7792C13.3634 11.8441 13.4154 11.922 13.4502 12.0078C13.485 12.0937 13.5019 12.1858 13.4998 12.2784C13.4998 12.4759 13.426 12.6483 13.2782 12.7962C13.2105 12.8636 13.1296 12.9165 13.0407 12.9514C12.9518 12.9864 12.8566 13.0028 12.7612 12.9996C12.6657 13.0028 12.5705 12.9864 12.4816 12.9514C12.3927 12.9165 12.3126 12.8636 12.2448 12.7962ZM5.30135 8.13631C6.22468 8.13631 7.00915 7.81308 7.65622 7.16587C8.30182 6.51866 8.62462 5.7324 8.62462 4.80782C8.62462 3.88324 8.30182 3.09772 7.65548 2.44977C7.00915 1.8033 6.22394 1.47933 5.30135 1.47933C4.37801 1.47933 3.59281 1.8033 2.94647 2.44977C2.30014 3.09772 1.97734 3.88324 1.97734 4.80782C1.97734 5.7324 2.30014 6.51866 2.94647 7.16587C3.59281 7.81308 4.37801 8.13631 5.30135 8.13631Z"
                                    fill="#CBCBCB" />
                            </svg>
                        </button>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Gallery3D;

